<template>
	<div>
		<a-modal v-model:visible="visible" width="750px">
			<template #title>
				<modalHeader title="盘点结果"></modalHeader>
			</template>
			<template #footer>
				<div style="text-align:center;">
					<a-button @click="close" type="primary">关闭</a-button>
					<a-button @click="onConfirm" v-if="list.status == 3">结果确认</a-button>
				</div>
			</template>
			
			<a-spin :spinning="loading">
				<div class="ui-title">当前盘点任务：{{taskName}}</div>
				<div class='ui-box'>
					<!-- <div class="ui-status color-red">
						结果待确认
					</div> -->
					<div class="ui-status  color-blue" v-if="list.status == 4">
						结果已确认
					</div>
					<div class="ui-status color-red" v-if="list.status == 3">
						结果待确认
					</div>
					<div class="ui-status color-red" v-if="list.status == 1 || list.status == 2">
						结果待提交
					</div>
					<a-form :label-col="{ span: 13 }">
						<a-form-item class="ui-result__item" label="应盘资产（件）">
							<div class="ui-result__num">{{list.num}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="实盘资产（件）">
							<div class="ui-result__num">{{list.alreadyNum}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="盘亏资产（件）">
							<div class="ui-result__num color-red">{{list.lossNum}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="盘盈资产（件）">
							<div class="ui-result__num color-blue">{{list.profitNum}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="应盘资产总价值（元）">
							<div class="ui-result__num">{{list.money}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="盘亏资产总价值（元）">
							<div class="ui-result__num color-red">{{list.lossMoney}}</div>
						</a-form-item>
						<a-form-item class="ui-result__item" label="盘盈资产总价值（元）">
							<div class="ui-result__num color-blue">{{list.profitMoney}}</div>
						</a-form-item>
					</a-form>
				</div>
				<!-- <div class="ui-warn">
					<Icon icon="WarningOutlined" style="margin-right: 8px;font-size: 20px;"></Icon>
					警告：本次盘点盘亏{{list.lossNum || 0}}件资产，请及时处理！
				</div> -->
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import { getInventoryResult, postAffirm } from '@/service/modules/inventory.js';
	import { Icon } from '@/components/icon/icon.js';
	import modalHeader from '@/components/modalHeader/index.vue';
	export default {
		components: { Icon, modalHeader },
		data() {
			return {
				loading: false,
				visible: false,
				list: {},
				taskName: undefined, // 当前任务名称
				unitTaskId: null
			}
		},
		created() {
			// this.getData(this.unitTaskId)
		},
		methods: {
			async getData(id) {
				this.loading = true;
				try {
					let ret = await getInventoryResult({
						unitTaskId: id, 
					});
					this.loading = false;
					if (ret.code === 200) {
						this.list = ret.data;
						this.pagination.total = ret.data.totalCount;
					}
				} catch(e) {
					this.loading = false;
				}
			},
			async onConfirm() {
				// this.onResult(this.taskName)
				this.loading = true;
				try {
					let ret = await postAffirm({
							unitTaskId: this.unitTaskId, 
						});
					this.loading = false;
					if (ret.code === 200) {
						this.$message.success("确认成功");
						this.$emit('onReset');
						this.close();
						// this.onResult(this.taskName)	
					}
				} catch(e) {
					this.loading = false;
				}
			},
			onResult(name){ // 携带任务名称跳转
				this.$router.push({
					name: 'inventoryManageResult',
					query: {
						name
					}
				})
			},
			open(e) {
				// if(str) {
				// 	let id = e.id
				// 	this.getData(id,str)
				// 	this.fromWhere = str
				// 	console.log("预警")
				// }else{
				// 	let unitTaskId = e.unitTaskId
				// 	console.log("非预警")
				// 	this.getData(unitTaskId,str)
				// }
				if(e) {
					this.unitTaskId = e.id
					// this.unitTaskId = 17
					this.taskName = e.taskName
					this.getData(this.unitTaskId)
				}
				this.visible = true;
			},
			close() {
				this.visible = false;
			}
		}
	}
</script>

<style scoped>
	.ui-title {
		font-size: 18px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #333333;
		line-height: 21px;
		text-align: center;
	}
	.ui-box {
		width: 80%;
		padding: 30px;
		margin: 30px auto 0;
		background: #F5F7FA;
		border-radius: 10px;
	}
	.ui-status {
		margin-bottom: 20px;
		font-size: 18px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #333333;
		line-height: 21px;
		text-align: center
	}
	.ui-result__item {
		margin-bottom: 0px;
	}
	.ui-result__num {
		font-size: 14px;
		font-family: DIN Alternate-Bold, DIN Alternate;
		font-weight: bold;
		color: #333333;
	}
	.color-red {
		color: rgba(247, 104, 91, 1);
	}
	.color-blue {
		color: rgba(73, 212, 242, 1);
	}
	.ui-warn {
		display: flex;
		margin-top: 20px;
		font-size: 14px;
		font-family: Noto Sans SC-Regular, Noto Sans SC;
		font-weight: 400;
		color: #F7685B;
		line-height: 16px;
		text-align: center;
		align-items: center;
		justify-content: center;
	}
</style>